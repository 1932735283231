/* INCONSOLATA FONT FAMILY */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
/* INTER FONT FAMILY */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* LORA FONT FAMILY */
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

/* TAILWIND UTILITY CLASSES */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONT FAMILIES */
.font_inconsolata {
  font-family: 'Inconsolata', monospace;
}

.font_inter {
  font-family: 'Inter', sans-serif;
}

.font_lora {
  font-family: 'Lora', serif;
}

/* CUSTOM CLASSES */
.custom_container {
  @apply w-full max-w-2xl m-auto;
}

/* SKIP TO MAIN CONTENT LINK */
.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  top: 20px;
  z-index: 999;
  opacity: 0;
  @apply p-5 rounded-md bg-light-100 dark:bg-dark-400;
}

.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

/* REMOVE ANIMATIONS FOR PEOPLE WHO'VE TURNED THEM OFF */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
